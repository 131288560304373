import React, { useState } from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import PlayVideo from "../PlayVideo/PlayVideo"
import "./ClientVideos.scss"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const ClientVideos = ({ data, pageData }) => {
  const [isPlay, setPlay] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const {isMobile} = useDeviceMedia()

  const handleVideo = item => {
    setPlay(true)
    setVideoUrl(item?.video_url)
  }

  const imagename = "page.client_video.carrer_list"
  return (
    <div className="client-opportunities-wrapper">
      <Container>
        <div className="client-opp-content">
          <div className="client-list">
            <CustomSlider
              dots={true}
              className="career-opportunity-slider"
              slidecount={isMobile?1:4}
            >
              {data?.add_item?.length > 0 &&
                data?.add_item?.map((item, index) => {
                  let processedImages = JSON.stringify({})
                  if (pageData?.imagetransforms) {
                    processedImages =
                      pageData?.imagetransforms?.client_video_Transforms
                  }
                  return (
                    <div className="career-item">
                      <div className="image-section">
                        
                        <GGFXImage
                            ImageSrc={item?.image}
                            altText={`${
                              item?.image?.alternativeText
                                ? item?.image?.alternativeText
                                : item.title
                            }`}
                            imagetransforms={pageData?.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={pageData?.strapi_id}
                        />
                        {item?.video_url && (
                          <button
                            onClick={() => handleVideo(item)}
                            className="play-button"
                          >
                            <i className="icon video-filled-icon" />
                          </button>
                        )}
                      </div>

                      <div className="career-name">
                        <h3 className="career_title">{item?.name}</h3>
                        <p className="career-sub_title">{item?.designation}</p>
                      </div>
                    </div>
                  )
                })}
            </CustomSlider>
          </div>
        </div>
      </Container>
      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={videoUrl}
        htmlink={""}
      />
    </div>
  )
}

export default ClientVideos
