import React from "react"
import ReviewStar from "../../images/star.svg"
import "./Rating.scss"
const Rating = ({ starRating }) => {
  return (
    <ul className="ratings">
      {starRating == "One" && (
        <li>
          <img src={ReviewStar} alt="review1" />
        </li>
      )}
      {starRating == "Two" && (
        <>
          <li>
            <img src={ReviewStar} alt="review1" />
          </li>
          <li>
            <img src={ReviewStar} alt="review2" />
          </li>
        </>
      )}
      {starRating == "Three" && (
        <>
          <li>
            <img src={ReviewStar} alt="review1" />
          </li>
          <li>
            <img src={ReviewStar} alt="review2" />
          </li>
          <li>
            <img src={ReviewStar} alt="review3" />
          </li>
        </>
      )}

      {(starRating == "Four" || starRating=="4") && (
        <>
          <li>
            <img src={ReviewStar} alt="review1" />
          </li>
          <li>
            <img src={ReviewStar} alt="review2" />
          </li>
          <li>
            <img src={ReviewStar} alt="review3" />
          </li>
          <li>
            <img src={ReviewStar} alt="review4" />
          </li>
        </>
      )}
      {(starRating == "Five" || starRating=="5") && (
        <>
          <li>
            <img src={ReviewStar} alt="review1" />
          </li>
          <li>
            <img src={ReviewStar} alt="review2" />
          </li>
          <li>
            <img src={ReviewStar} alt="review3" />
          </li>
          <li>
            <img src={ReviewStar} alt="review4" />
          </li>
          <li>
            <img src={ReviewStar} alt="review5" />
          </li>
        </>
      )}
    </ul>
  )
}

export default Rating
